<template>
  <div style="position: relative">
    <div style="position: absolute; top: 6vh; left: 20vw; width: 50%">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="账号:">
          <el-input v-model="form.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户名:" prop="realName">
          <el-input v-model="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="tel">
          <el-input v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')">保存</el-button>
        </el-form-item>
      </el-form>
      <el-form ref="form2" :model="form2" :rules="rules2" label-width="120px">
        <el-form-item label="原密码:" prop="oldPass">
          <el-input type="password" v-model.trim="form2.oldPass"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPass">
          <el-input type="password" v-model.trim="form2.newPass"></el-input>
        </el-form-item>
        <el-form-item label="重复新密码:" prop="newPassTwo">
          <el-input type="password" v-model.trim="form2.newPassTwo"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit2('form2')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      form: {
        userName: "",
        realName: "",
        tel: "",
        email: "",
        id:"",
      },
      form2: {
        oldPass: "",
        newPass:"",
        newPassTwo: "",
      },
      rules: {
        realName: [{ required: true, message: "请输入", trigger: "blur" }],
        tel: [{ required: true, message: "请输入", trigger: "blur" }],
        email: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      rules2: {
        oldPass: [{ required: true, message: "请输入", trigger: "blur" }],
        newPass: [{ required: true, message: "请输入", trigger: "blur" }],
        newPassTwo: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    // 获取回显信息
    getDetails() {
      this.$get("/getLoginUserInfo").then((res) => {
        if (res.data.state == "success") {
          //console.log(res.data)
          this.form = {...res.data.data};
          //console.log(this.form,"form")
        }
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$post("/userManage/updateUserInfo", this.form
            ).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("保存成功");
              } else {
                this.$message.error(res.data.msg);
              }
            });
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmit2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //console.log(this.form2.newPass,this.form2.newPassTwo)
          if (this.form2.newPass != this.form2.newPassTwo) {
            this.$message.error("重复密码与新密码不相同");
            return;
          }
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$post("/bigData/updateMyInfo", {
              newPass: this.form2.newPass,
              oldPass: this.form2.oldPass,
              id:this.form.id,
            }).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("保存成功");
              } else {
                this.$message.error(res.data.msg);
              }
            });
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
  